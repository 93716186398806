<script>
import CustomerList from '@app/components/CustomerList.vue';
import CustomerExportForm from '@app/components/CustomerExportForm.vue';

export default {
  name: 'Customers',
  components: { CustomerList, CustomerExportForm },
  head: {
    title: 'Apprenants',
  },
  data: () => ({ isExportingModalOpen: false, isExporting: false }),
};
</script>

<template>
  <div>
    <div class="level is-mobile">
      <div class="level-left">
        <h1 class="title is-size-4-mobile">
          Apprenants
        </h1>
      </div>
      <div class="level-right">
        <b-tooltip label="Compatible Excel &amp; Google Sheet" position="is-left">
          <b-button type="is-primary" icon-left="file-export" @click="isExportingModalOpen = true" :loading="isExporting">
            Export CSV
          </b-button>
        </b-tooltip>
      </div>
    </div>
    <b-modal :width="460" :active.sync="isExportingModalOpen">
      <div class="box">
        <CustomerExportForm @done="isExportingModalOpen = false" />
      </div>
    </b-modal>

    <CustomerList />
  </div>
</template>
